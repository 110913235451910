import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

Sentry.init({
  dsn: 'https://df4ca515b1b7f7ff2609b72b6647b66f@o4506128364863488.ingest.us.sentry.io/4507153449025536',
  environment: ENV.ENVIRONMENT,
  release: ENV.APP_VERSION,
  enabled: ['develop', 'production'].includes(ENV.ENVIRONMENT || ''),
  tracesSampleRate: 1,
  tracesSampler(samplingContext) {
    // Attempt to ignore all static asset requests
    const url = samplingContext.request?.url;
    const ignoreRegex = new RegExp(/\.(js|css|svg|png|jpg|woff2|ico)$/);
    if (url && ignoreRegex.test(url)) {
      return 0;
    }

    return 1;
  },
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
      enableInp: true
    }),
    Sentry.replayIntegration()
  ]
});
